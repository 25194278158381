import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const HomeVideoBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 1;
`

const DesktopVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  div {
    position: relative;
  }

  iframe,
  video {
    width: 310% !important;
    height: 310% !important;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px;

    @media (max-width: 6200px) {
      width: 280% !important;
      height: 280% !important;
    }

    @media (max-width: 5200px) {
      width: 230% !important;
      height: 230% !important;
    }

    @media (max-width: 3800px) {
      width: 200% !important;
      height: 200% !important;
    }

    @media (max-width: 2600px) {
      width: 150% !important;
      height: 140% !important;
    }

    @media (max-width: 1800px) {
      width: 150% !important;
      height: 120% !important;
    }

    @media (max-width: 1200px) {
      width: 200% !important;
      height: 100% !important;
    }

    @media (max-width: 850px) {
      width: 250% !important;
    }

    @media (max-width: 650px) {
      width: 300% !important;
    }
  }

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileVideoWrapper = styled.div`
  width: 100%;
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  iframe,
  video {
    width: 140% !important;
    height: 140% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    border: 0px;

    @media (max-width: 480px) {
      width: 130% !important;
      height: 120% !important;
    }

    @media (max-width: 420px) {
      width: 125% !important;
      height: 100% !important;
    }

    @media (max-width: 350px) {
      width: 130% !important;
      height: 100% !important;
    }
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const TopSection = styled.div`
  display: flex;
  background: #e5e5e5;
  width: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #fff;
  min-height: 800px;

  @media (max-width: 6200px) {
    min-height: 700px;
  }

  @media (max-width: 3800px) {
    min-height: 600px;
  }

  @media (max-width: 2400px) {
    min-height: 510px;
  }

  @media (max-width: 1600px) {
    min-height: 450px;
  }

  @media (max-width: 1600px) {
    min-height: 450px;
  }

  @media (max-width: 550px) {
    min-height: 400px;
  }

  :hover {
    color: #fff;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-width: 1200px;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  padding: 0px 20px;
  z-index: 1;
`

const Title = styled.div`
  font-size: 60px;
  font-size: 48px;
  line-height: 1.2;
  color: #fff;
  font-family: "Museo";
  font-weight: 400;
  text-align: center;

  span {
    display: block;
    font-weight: bold;
  }

  @media (max-width: 850px) {
    font-size: 48px;
    font-size: 36px;
  }

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileTitle = styled.div`
  font-size: 32px;
  font-size: 24px;
  line-height: 1.1;
  color: #fff;
  font-family: "Museo";
  font-weight: 400;
  text-align: center;
  display: none;

  span {
    display: block;
    font-weight: bold;
  }

  @media (max-width: 550px) {
    display: block;
  }

  @media (max-width: 350px) {
    font-size: 24px;
  }
`

const Button = styled(Link)`
  background: #262626;
  border-radius: 6px;
  padding: 15px 20px;
  color: #fff;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  min-width: 220px;
  text-align: center;
  margin-top: 10px;
  border-radius: 25px 0px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(
      61.74deg,
      #ff7881 -4.05%,
      #ffab61 52.44%,
      #ffe775 91.92%
    );
    border-radius: 25px 0;
    z-index: -1;
  }

  :hover {
    color: #fff;
  }

  @media (max-width: 550px) {
    display: none;
  }
`

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  top: 30px;
  padding: 0px 30px;
  z-index: 1;

  @media (max-width: 1600px) {
    padding: 0px 20px;
  }

  @media (max-width: 550px) {
    display: none;
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  bottom: 30px;
  padding: 0px 30px;
  z-index: 1;

  @media (max-width: 1600px) {
    padding: 0px 20px;
  }
`

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 550px) {
    justify-content: center;
  }
`

const GrandmaImage = styled.img`
  max-width: 110px;
  width: 100%;
  margin: 0px;

  @media (max-width: 850px) {
    max-width: 90px;
  }
`

const GrandmaMobileImage = styled.img`
  max-width: 65px;
  height: 100%;
  max-height: 65px;
  width: 100%;
  margin: 0px 15px 0px 0px;
  display: none;

  @media (max-width: 550px) {
    max-width: 50px;
    max-height: 50px;
    display: block;
  }
`

const MedicaleImage = styled.img`
  max-width: 65px;
  height: 100%;
  max-height: 65px;
  width: 100%;
  margin: 0px 15px 0px 0px;

  @media (max-width: 550px) {
    max-width: 50px;
    max-height: 50px;
  }
`

const InnovationImage = styled.img`
  max-width: 65px;
  max-height: 65px;
  height: 100%;
  width: 100%;
  margin: 0px;

  @media (max-width: 550px) {
    max-width: 50px;
    max-height: 50px;
  }
`

const MobileButton = styled(Link)`
  background: #262626;
  border-radius: 6px;
  padding: 15px 20px;
  color: #fff;
  font-family: "Museo";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  min-width: 220px;
  text-align: center;
  margin-top: 10px;
  display: none;
  border-radius: 25px 0px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(
      61.74deg,
      #ff7881 -4.05%,
      #ffab61 52.44%,
      #ffe775 91.92%
    );
    border-radius: 25px 0;
    z-index: -1;
  }

  :hover {
    color: #fff;
  }

  @media (max-width: 550px) {
    display: block;
    text-align: center;
  }
`

export const HomeVideoBanner = ({ intl, data }) => {
  return (
    data && (
      <HomeVideoBannerWrapper>
        <TopSection className="HomeVideoBanner">
          {data.video_section_text.button_text &&
            data.video_section_text.button_url && (
              <AbsoluteButton to={data.video_section_text.button_url}>
                {data.video_section_text.button_text}
              </AbsoluteButton>
            )}
          <DesktopVideoWrapper>
            <ReactPlayer
              url="https://www.percko.com/videos/percko.com/gbp-en/home-page/Home_Page_-_Video_1_Desktop.mp4"
              playing={true}
              controls={false}
              muted={true}
              loop={true}
              width="100%"
              height="100%"
              playsinline={true}
              playsInline
            />
          </DesktopVideoWrapper>
          <MobileVideoWrapper>
            <ReactPlayer
              url="https://www.percko.com/videos/percko.com/gbp-en/home-page/Home_Page_-_Video_1_Mobile.mp4"
              playing={true}
              controls={false}
              muted={true}
              loop={true}
              width="100%"
              height="100%"
              playsinline={true}
              playsInline
            />
          </MobileVideoWrapper>
          {/* {intl.locale === "fr" && process.env.GATSBY_ACTIVE_ENV === "eur" && (
            <TopWrapper>
              {data.video_section_text.medical_image &&
                data.video_section_text.innovation_image && (
                  <IconWrapper>
                    <GrandmaImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/f7122bca-6444-4aa8-bb3e-703d1a14ea9c.svg"
                    />
                  </IconWrapper>
                )}
            </TopWrapper>
          )} */}
          {/* {intl.locale === "en" && process.env.GATSBY_ACTIVE_ENV === "gbp" && (
            <TopWrapper>
              {data.video_section_text.medical_image &&
                data.video_section_text.innovation_image && (
                  <IconWrapper>
                    <GrandmaImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/7b542d3c-3040-4013-84f5-657aaf376314.svg"
                    />
                  </IconWrapper>
                )}
            </TopWrapper>
          )} */}
          <TextWrapper lang={intl.locale}>
            {data.video_section_text.title && (
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.video_section_text.title,
                }}
              />
            )}
            {data.video_section_text.mobile_title && (
              <MobileTitle
                dangerouslySetInnerHTML={{
                  __html: data.video_section_text.mobile_title,
                }}
              />
            )}
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <Button to={data.video_section_text.button_url}>
                  {data.video_section_text.button_text}
                </Button>
              )}
          </TextWrapper>
          <BottomWrapper>
            {data.video_section_text.medical_image &&
              data.video_section_text.innovation_image && (
                <IconWrapper>
                  {/* {intl.locale === "fr" &&
                    process.env.GATSBY_ACTIVE_ENV === "eur" && (
                      <GrandmaMobileImage
                        className="lazyload"
                        data-src="https://static.percko.com/uploads/f7122bca-6444-4aa8-bb3e-703d1a14ea9c.svg"
                      />
                    )} */}
                  {/* {intl.locale === "en" &&
                    process.env.GATSBY_ACTIVE_ENV === "gbp" && (
                      <GrandmaMobileImage
                        className="lazyload"
                        data-src="https://static.percko.com/uploads/7b542d3c-3040-4013-84f5-657aaf376314.svg"
                      />
                    )} */}
                  <MedicaleImage
                    className="lazyload"
                    data-src={data.video_section_text.medical_image}
                  />
                  <InnovationImage
                    className="lazyload"
                    data-src={data.video_section_text.innovation_image}
                  />
                </IconWrapper>
              )}
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <MobileButton to={data.video_section_text.button_url}>
                  {data.video_section_text.button_text}
                </MobileButton>
              )}
          </BottomWrapper>
        </TopSection>
      </HomeVideoBannerWrapper>
    )
  )
}

export default injectIntl(HomeVideoBanner)
